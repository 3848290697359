import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { ElxDropdown, ElxTextField, InputMessageTypes, } from "@elixir/components";
import { Checkbox, Label, Stack, } from "@fluentui/react";
import { FieldLabel } from "../../commoncomponents/FieldLabel";
import { FormRichTextEditor } from "../../commoncomponents/FormRichTextEditor";
import { AttachmentDetail } from "../../commoncomponents/Attachments";
import { useRootContext } from "../../library/providers/root/RootContextProvider";
import { UseConsumerContextDispatch, UseConsumerContextState, } from "../../reducers";
import { isAlphaNumeric } from "../../library/utilities/FieldValidation";
import { useLocaleStrings } from "../../library/providers/strings";
import { AttachmentExtensions } from "../../library/Constants/AttachmentExtensions";
import "../../styles/EscalationForm.css";
import "./HomeUser.css";
export const EscalationDetailsTab = (props) => {
    const { getLocalSpecificString } = useLocaleStrings();
    const [isTabInitialized, setIsTabInitialized] = useState(true);
    const { privileges: { isQALoB }, } = useRootContext();
    const consumerContextState = UseConsumerContextState();
    const setConsumerContextState = UseConsumerContextDispatch();
    const [escalationDetails, setEscalationDetails] = useState(consumerContextState.ConsumerContext.EscalationDetails);
    const [validationStatus, setValidationStatus] = useState(consumerContextState.ConsumerContext.areEscalationDetailsValid !== true
        ? {
            validIssueType: undefined,
            validProductFamily: undefined,
            validIssueDescription: undefined,
            validSupportRequestNumber: true,
            validConfirmation: undefined,
            validFreeTextLength: true,
        }
        : {
            validIssueType: true,
            validProductFamily: true,
            validIssueDescription: true,
            validSupportRequestNumber: true,
            validConfirmation: true,
            validFreeTextLength: true,
        });
    const setValidFreeTextLength = (isValidLength) => {
        setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validFreeTextLength: isValidLength })));
    };
    const [productFamilyOptions, setProductFamilyOptions] = useState([]);
    useEffect(() => {
        if (props.escalationNextClicked > 0 && !isTabInitialized) {
            validateEscalationTab();
        }
    }, [props.escalationNextClicked]);
    useEffect(() => {
        if (isTabInitialized) {
            setIsTabInitialized(false);
            if (escalationDetails.escalationSourceId == "") {
                //setting this way as defaultselectedkey prop in elixir dropdown doesn't work as expected
                const defaultCaseSource = consumerContextState.ConsumerContext.ConsumerMetadata.flags.find((it) => it.text === "Advocacy");
                if (defaultCaseSource) {
                    setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { escalationSourceId: defaultCaseSource.key.toString(), escalationSource: defaultCaseSource.text })));
                }
            }
            if (escalationDetails.issueTypeId != "") {
                setIssueAndProductFamilies(escalationDetails.issueTypeId, escalationDetails.issueType, escalationDetails.productFamilyId, escalationDetails.productFamily);
            }
        }
        else {
            let isValid = undefined;
            if (Object.values(validationStatus).every((value) => value === true)) {
                isValid = true;
            }
            else if (Object.values(validationStatus).some((value) => value === false)) {
                isValid = false;
            }
            setConsumerContextState.UpdateEscalationDetails(escalationDetails);
            setConsumerContextState.UpdateEscalationDetailsValidationStatus(isValid);
        }
    }, [
        validationStatus,
        escalationDetails.escalationSource,
        escalationDetails.attachments,
    ]);
    const validateEscalationTab = () => {
        var _a;
        const valueHolder = document.createElement("div");
        valueHolder.innerHTML = escalationDetails.issueDescription;
        const isIssueDescriptionValid = valueHolder.innerText.trim() != "" ||
            ((_a = valueHolder.textContent) === null || _a === void 0 ? void 0 : _a.trim()) != "";
        const validationResult = {
            validIssueType: escalationDetails.issueTypeId != "",
            validProductFamily: productFamilyOptions.length == 0 ||
                (productFamilyOptions.length > 0 &&
                    escalationDetails.productFamilyId != ""),
            validIssueDescription: isIssueDescriptionValid,
            validFreeTextLength: validationStatus.validFreeTextLength,
            validSupportRequestNumber: !escalationDetails.supportRequestNumber ||
                escalationDetails.supportRequestNumber.trim() == "" ||
                isAlphaNumeric(escalationDetails.supportRequestNumber),
            validConfirmation: escalationDetails.declarationCheck,
        };
        setValidationStatus(validationResult);
        announceErrors();
        let isValid = true;
        Object.values(validationResult).forEach((x) => {
            if (x === false || x === undefined) {
                isValid = false;
                return false;
            }
        });
        // delay as workaround to make sure context is updated before this
        setTimeout(() => {
            if (props.moveToNextTab)
                props.moveToNextTab(isValid);
        }, 500);
    };
    const announceErrors = () => {
        const errorElement = document.querySelector(".has-error");
        if (errorElement) {
            const liveElements = errorElement.querySelectorAll('div[aria-live="assertive"]');
            const firstErrorElement = Array.from(liveElements).find((element) => element.innerText.trim() !== '');
            //trigger change in aria-live content to announce error
            if (firstErrorElement) {
                const htmlContent = firstErrorElement.innerHTML;
                firstErrorElement.innerHTML = htmlContent;
            }
        }
    };
    const setEscalationSource = (_event, option, _index) => {
        if (option) {
            setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { escalationSourceId: option.key.toString(), escalationSource: option.key.toString() != "" ? option.text : "" })));
            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validEscalationSource: true })));
        }
    };
    const setIssueType = (_event, option, _index) => {
        if (option) {
            setIssueAndProductFamilies(option.key.toString(), option.text);
        }
    };
    const setIssueAndProductFamilies = (issueTypeId, issueType, productFamilyId = "", productFamily = "") => {
        const productTypeIds = consumerContextState.ConsumerContext.ConsumerMetadata.dfCRoutings
            .filter((item) => item.issueTypeId == issueTypeId)
            .map((item) => item.ProductTypeId);
        const productFamilies = consumerContextState.ConsumerContext.ConsumerMetadata.productFamilies.filter((product) => productTypeIds.includes(product.key));
        productFamilies.sort((a, b) => {
            if (a.text === "Other")
                return 1;
            if (b.text === "Other")
                return -1;
            return a.text.localeCompare(b.text);
        });
        setProductFamilyOptions(productFamilies);
        setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { issueTypeId,
            issueType,
            productFamilyId,
            productFamily })));
        setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validIssueType: true, validProductFamily: productFamilies.length == 0 || productFamilyId != "" ? true : undefined })));
    };
    const setProductFamily = (_event, option, _index) => {
        if (option) {
            setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { productFamilyId: option.key.toString(), productFamily: option.text })));
            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validProductFamily: true })));
        }
    };
    const setIssueDescription = (newValue) => {
        setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { issueDescription: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const formRichTextEditor = useMemo(() => {
        return (_jsx(FormRichTextEditor, { ariaLabel: getLocalSpecificString("issueDescriptionLabel"), id: "issueDescription", value: escalationDetails.issueDescription, onChange: setIssueDescription, required: true, onBlur: () => {
                var _a;
                const valueHolder = document.createElement("div");
                valueHolder.innerHTML = escalationDetails.issueDescription;
                const isValid = valueHolder.innerText.trim() != "" ||
                    ((_a = valueHolder.textContent) === null || _a === void 0 ? void 0 : _a.trim()) != "";
                setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validIssueDescription: isValid })));
            }, maxLength: 80000, setIsLengthValid: setValidFreeTextLength, message: validationStatus.validIssueDescription === false
                ? {
                    type: InputMessageTypes.Error,
                    content: getLocalSpecificString("issueDescriptionEM"),
                }
                : undefined }));
    }, [
        escalationDetails.issueDescription,
        validationStatus.validIssueDescription,
    ]);
    const setSupportRequestNumber = (_event, newValue) => {
        setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { supportRequestNumber: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const setAttachments = (attachmentData) => {
        setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { attachments: attachmentData })));
    };
    const attachmentDetail = useMemo(() => {
        return (_jsx(AttachmentDetail, { "aria-describedby": "attachmentsLabel", filesToBeUpload: escalationDetails.attachments, setFilesToBeUpload: setAttachments, attachments: [], maxSizeForAttachment: 5, totalMaxSizeForAttachments: 10, allowedFileTypes: AttachmentExtensions, primaryAction: {}, secondaryAction: {}, onDelete: () => {
                return;
            }, styles: {
                subComponentStyles: {
                    attachmentAddContainer: { root: { margin: "" } },
                },
            } }));
    }, [escalationDetails.attachments]);
    return (_jsxs(Stack, Object.assign({ className: "escalationForm" }, { children: [isQALoB && (_jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ id: "escalationSourceLabel" }, { children: getLocalSpecificString("escalationSourceLabel") })), _jsx(ElxDropdown, { "aria-labelledby": "escalationSourceLabel", options: consumerContextState.ConsumerContext.ConsumerMetadata.flags, selectedKey: escalationDetails.escalationSourceId, onChange: setEscalationSource })] }))), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: true, id: "issueTypeLabel" }, { children: getLocalSpecificString("issueTypeLabel") })), _jsx(ElxDropdown, { "aria-labelledby": "issueTypeLabel", placeholder: getLocalSpecificString("issueTypePH"), options: consumerContextState.ConsumerContext.ConsumerMetadata.issueTypes, selectedKey: escalationDetails.issueTypeId, onChange: setIssueType, required: true, message: validationStatus.validIssueType === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please select a valid issue type",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ id: "productFamilyLabel", required: true }, { children: getLocalSpecificString("productFamilyLabel") })), _jsx(ElxDropdown, { "aria-labelledby": "productFamilyLabel", placeholder: getLocalSpecificString("productFamilyPH"), options: productFamilyOptions, selectedKey: escalationDetails.productFamilyId, onChange: setProductFamily, disabled: productFamilyOptions.length == 0, required: true, message: validationStatus.validProductFamily === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please select a valid product family",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: true, id: "issueDescriptionLabel" }, { children: getLocalSpecificString("issueDescriptionLabel") })), formRichTextEditor] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ id: "supportRequestNumberLabel" }, { children: getLocalSpecificString("supportRequestNumberLabel") })), _jsx(ElxTextField, { "aria-labelledby": "supportRequestNumberLabel", placeholder: getLocalSpecificString("supportRequestNumberPH"), value: escalationDetails.supportRequestNumber, onChange: setSupportRequestNumber, invalid: validationStatus.validSupportRequestNumber === false, onBlur: () => {
                            const isValid = !escalationDetails.supportRequestNumber ||
                                escalationDetails.supportRequestNumber.trim() == "" ||
                                isAlphaNumeric(escalationDetails.supportRequestNumber);
                            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validSupportRequestNumber: isValid })));
                        }, message: validationStatus.validSupportRequestNumber === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please enter a valid support request number",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(FieldLabel, { label: getLocalSpecificString("attachmentsLabel"), mandatory: false, toolTipContent: _jsxs(Label, Object.assign({ id: "attachmentsLabel" }, { children: ["Each file may be up to 5 MB in size. Attachments may not totally exceed 10 MB.", _jsx("br", {}), _jsx("b", { children: "Permitted File Extensions:" }), _jsx("br", {}), getLocalSpecificString("permittedFileExtensions")] })) }), attachmentDetail] })), _jsxs(Stack.Item, Object.assign({ className: "chkConfirmationStackItem" }, { children: [_jsxs("div", Object.assign({ className: "chkConfirmationDiv" }, { children: [_jsx(Checkbox, { "aria-describedby": "confirmationStatement", id: "chkConfirmation", required: true, checked: escalationDetails.declarationCheck, onChange: (_event, checked) => {
                                    setEscalationDetails((prevState) => (Object.assign(Object.assign({}, prevState), { declarationCheck: checked !== null && checked !== void 0 ? checked : false })));
                                    setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validConfirmation: checked !== null && checked !== void 0 ? checked : false })));
                                } }), _jsxs("label", Object.assign({ id: "confirmationStatement", className: "chkConfirmationLabel" }, { children: [getLocalSpecificString("confirmationStatement"), _jsx("span", Object.assign({ className: "asterisk" }, { children: " *" }))] }))] })), validationStatus.validConfirmation === false && (_jsx("div", Object.assign({ className: "chkErrorMessage" }, { children: "This is a mandatory field" })))] }))] })));
};
