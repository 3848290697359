import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef, useEffect, useCallback, useState, } from "react";
import { IconButton, TooltipHost } from "@fluentui/react";
const getFocusableElement = (parent, position) => {
    if (parent) {
        const focusableElements = Array.from(parent.querySelectorAll('a, button, input, textarea, [tabindex]:not([tabindex="-1"])'));
        return position === "first"
            ? focusableElements[0] || null
            : focusableElements[focusableElements.length - 1] || null;
    }
    return null;
};
const FocusableTooltip = ({ trigger, children, onTooltipBlur, }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const tooltipRef = useRef(null);
    const triggerRef = useRef(null);
    const handleLastKeyDown = useCallback((event) => {
        if (event.key === "Tab" && !event.shiftKey) {
            event.preventDefault();
            onTooltipBlur === null || onTooltipBlur === void 0 ? void 0 : onTooltipBlur();
        }
    }, [onTooltipBlur]);
    const handleFirstKeyDown = useCallback((event) => {
        var _a;
        if (event.key === "Tab" && event.shiftKey) {
            event.preventDefault();
            (_a = triggerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    useEffect(() => {
        const firstFocusable = getFocusableElement(tooltipRef.current, "first");
        const lastFocusable = getFocusableElement(tooltipRef.current, "last");
        if (isTooltipVisible) {
            firstFocusable === null || firstFocusable === void 0 ? void 0 : firstFocusable.addEventListener("keydown", handleFirstKeyDown);
            lastFocusable === null || lastFocusable === void 0 ? void 0 : lastFocusable.addEventListener("keydown", handleLastKeyDown);
        }
        return () => {
            firstFocusable === null || firstFocusable === void 0 ? void 0 : firstFocusable.removeEventListener("keydown", handleFirstKeyDown);
            lastFocusable === null || lastFocusable === void 0 ? void 0 : lastFocusable.removeEventListener("keydown", handleLastKeyDown);
        };
    }, [isTooltipVisible, handleLastKeyDown, handleFirstKeyDown]);
    const handleTriggerKeyDown = (event) => {
        if (event.key === 'Tab' && isTooltipVisible && !event.shiftKey) {
            event.preventDefault();
            const firstFocusable = getFocusableElement(tooltipRef.current, "first");
            firstFocusable === null || firstFocusable === void 0 ? void 0 : firstFocusable.focus();
        }
    };
    const handleTooltipTrigger = (isTooltipVisible) => {
        setIsTooltipVisible(isTooltipVisible);
    };
    return (_jsx(TooltipHost, Object.assign({ content: _jsx("div", Object.assign({ ref: tooltipRef }, { children: children })), onTooltipToggle: handleTooltipTrigger }, { children: trigger ?
            React.cloneElement(trigger, {
                ref: triggerRef,
                onKeyDown: handleTriggerKeyDown,
            })
            :
                _jsx("div", Object.assign({ tabIndex: 0, className: "custom-tooltip-icon", ref: triggerRef, onKeyDown: handleTriggerKeyDown }, { children: _jsx(IconButton, { tabIndex: -1, iconProps: { iconName: "Info" }, className: "infoIcon pad-0", "aria-label": "Info" }) })) })));
};
export default FocusableTooltip;
