var _a, _b;
import { jsx as _jsx } from "react/jsx-runtime";
import { getCurrentUser } from "@elixir/auth";
import React, { useReducer } from "react";
import { CommercialFlowContextReducer } from "./CommercialFlowContextReducer";
import submitCommercialEscalation, * as CommercialFlowActions from "../../reducers/CommercialFlowContext/Actions";
import { enrichCommercialCase } from "./Transformers/EnrichCommercialRequest";
import { FlowTypeSelector } from "../../models";
import { useLocaleStrings } from "../../library/providers/strings";
import { useCommercialContextState } from "../CommercialContext/CommercialContextProvider";
export const initialState = {
    BasicDetails: {
        submitterName: ((_a = getCurrentUser()) === null || _a === void 0 ? void 0 : _a.displayName) || "",
        submitterEmail: ((_b = getCurrentUser()) === null || _b === void 0 ? void 0 : _b.account) || "",
        submitterOnBehalfOfName: "",
        submitterOnBehalfOfEmail: "",
        customer: {
            tpid: "",
            msSalesID: "",
            accountNumber: "",
            companyName: "",
            subsidiary: "",
            salesRegion: "",
            atuGroupName: "",
            atuManagerAlias: "",
            atuAccountExecutiveAlias: "",
            segment: "",
            subSegment: "",
        },
        partner: {
            partnerId: "",
            partnerName: "",
            parentIdNumber: "",
            parentName: "",
            subsidiaryName: "",
            segmentName: "",
            subSegmentName: "",
        },
        customerLocation: {
            key: "",
            text: "",
        },
        isCSAMSelected: { key: "", text: "" },
        isDirectCommunicationRequired: { key: "No", text: "No" },
        customerFirstName: "",
        customerLastName: "",
        customerEmail: "",
        customerPhone: "",
        additionalInternalStakeholders: [],
        licenseQSubProgram: { key: "", text: "" },
        customerOrPartnerSelection: { key: "customer", text: "Customer" },
        partnerLocation: { key: "", text: "" },
        onBehalfOf: undefined,
        isCustomerOrCommercialCase: { key: "consumer", text: "HomeUser" },
        additionalStakeholdersGroupsDLs: [],
    },
    EscalationDetails: {
        caseSource: undefined,
        purchasingChannel: { key: "", text: "" },
        issueDescription: "",
        desiredOutcome: "",
        businessImpact: "",
        additonalAffectedProducts: [],
        additionalAffectedPrograms: [],
        agreementNumber: "",
        tenantIds: [],
        tenantName: "",
        tenantType: { key: "", text: "" },
        isTenantChecked: false,
        supportTicketIds: [],
        attachments: [],
        program: {
            key: "",
            text: "",
        },
        enrollmentNumber: "",
        escalationSource: { key: 1, text: "Standard" },
        elpEscalationSource: { key: 5, text: "Executive" },
        parentProduct: { key: "", text: "" },
        component: { key: "", text: "" },
        requestRelatedTo: { key: "", text: "" },
        azureSubscriptionType: { key: "", text: "" },
        azureSubscriptionID: "",
        isServiceInterruptionOutage: { key: "No", text: "No" },
        serviceIncidentNumber: "",
        businessRisk: { key: "", text: "" },
        engagementID: "",
        opportunityID: "",
        impactedAmount: "",
        totalAmount: "",
        impactedUsers: "",
        totalUsers: "",
        deploymentDate: undefined,
        issueArea: { key: "product", text: "Product or service" },
        escalationDate: "",
        affectedProduct: { key: "", text: "" },
        importNotes: "",
    },
    ValidationStatus: {
        BasicDetailsValidationStatus: {
            validCustomer: true,
            validCustomerLocation: true,
            validCustomerFirstName: true,
            validCustomerLastName: true,
            validCustomerEmail: true,
            validCustomerPhone: true,
            validSubmitter: true,
            validMicrosoftAccountId: true,
            validSubmitterOnBehalf: true,
        },
        EscalationDetailsValidationStatus: {
            validPurchasingChannel: true,
            validIssueDescription: true,
            validFreeTextLength: true,
            validTenantName: true,
            validSupportCase: true,
            validEscalationSource: true,
            validSupportRequestNumber: true,
            validConfirmation: true,
            validProgram: true,
        },
    },
    areBasicDetailsValid: false,
    areEscalationDetailsValid: false,
    uemTrackingId: undefined,
    isEU: false,
    isAzure: false,
    InProgress: false,
    Error: "",
};
export const useCommercialFlowContext = () => {
    return React.useContext(CommercialFlowContext);
};
export const useCommercialFlowContextDispatch = () => {
    return React.useContext(CommercialFlowContextDispatch);
};
export const CommercialFlowContext = React.createContext(initialState);
export const CommercialFlowContextDispatch = React.createContext({});
export const CommercialFlowContextProvider = ({ children, }) => {
    const [context, contextDispatch] = useReducer(CommercialFlowContextReducer, initialState);
    const commercialContextState = useCommercialContextState();
    const { getLocalSpecificString } = useLocaleStrings();
    const update_BasicDetails = (payload) => {
        CommercialFlowActions.basicDetailsUpdateAction(payload, contextDispatch);
    };
    const update_EscalationDetails = (payload) => {
        CommercialFlowActions.escalationDetailsCompleteUpdateAction(payload, contextDispatch);
    };
    const set_BasicDetailsAreValid = (payload) => {
        CommercialFlowActions.basicDetailsAreValidAction(payload, contextDispatch);
    };
    const set_EscalationDetailsAreValid = (payload) => {
        CommercialFlowActions.escalationDetailsAreValidAction(payload, contextDispatch);
    };
    const fetchCountryDetails = (countryName) => {
        var _a;
        let countryDetails;
        Object.values((_a = commercialContextState.CommercialContext.CommercialMetadata) === null || _a === void 0 ? void 0 : _a.countries).forEach((country) => {
            if (countryName === country.name)
                countryDetails = country;
        });
        return countryDetails;
    };
    const fetchProgramName = (flowType) => {
        switch (flowType) {
            // PSST has no program name field, setting it by
            case `${FlowTypeSelector.PSST}`:
                return getLocalSpecificString("psstProgramName");
            case `${FlowTypeSelector.License}`:
                return getLocalSpecificString("licenseqProgramName");
        }
        return "";
    };
    const fetchParentProgramName = (flowType) => {
        switch (flowType) {
            case `${FlowTypeSelector.PSST}`:
                return getLocalSpecificString("psstParentProgramName");
            case `${FlowTypeSelector.License}`:
                return getLocalSpecificString("licenseqProgramName");
        }
        return "";
    };
    const submit_CommercialCase = (payload, flowType, flowTypeText) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
        // Transforming commercial flow context model to commercial escalation
        const transformedEscalation = enrichCommercialCase(payload, flowType, flowTypeText);
        if (transformedEscalation.IssueType === "Program") {
            transformedEscalation.Products = [];
            transformedEscalation.Programs = [
                {
                    programId: ((_b = (_a = payload.EscalationDetails.program) === null || _a === void 0 ? void 0 : _a.key) === null || _b === void 0 ? void 0 : _b.toString().length) > 0
                        ? (_d = (_c = payload.EscalationDetails.program) === null || _c === void 0 ? void 0 : _c.key) === null || _d === void 0 ? void 0 : _d.toString()
                        : "0",
                    programName: ((_e = payload.EscalationDetails.program) === null || _e === void 0 ? void 0 : _e.text.length) > 0
                        ? (_f = payload.EscalationDetails.program) === null || _f === void 0 ? void 0 : _f.text
                        : fetchProgramName(flowType),
                    parentProgramName: (_j = (_h = (_g = payload.EscalationDetails.program) === null || _g === void 0 ? void 0 : _g.title) === null || _h === void 0 ? void 0 : _h.split("$$")[1]) !== null && _j !== void 0 ? _j : fetchParentProgramName(flowType),
                    parentProgramId: ((_m = (_l = (_k = payload.EscalationDetails.program) === null || _k === void 0 ? void 0 : _k.title) === null || _l === void 0 ? void 0 : _l.split("$$")[0]) !== null && _m !== void 0 ? _m : 0),
                    isPrimary: true,
                },
            ];
            if (flowType === FlowTypeSelector.License) {
                transformedEscalation.Programs[0].programName =
                    (_p = (_o = payload.BasicDetails.licenseQSubProgram) === null || _o === void 0 ? void 0 : _o.text) !== null && _p !== void 0 ? _p : fetchProgramName(flowType);
                transformedEscalation.Programs[0].programId =
                    (_r = (_q = payload.BasicDetails.licenseQSubProgram) === null || _q === void 0 ? void 0 : _q.key.toString()) !== null && _r !== void 0 ? _r : "0";
            }
            if (flowType === FlowTypeSelector.CPP) {
                transformedEscalation.Programs[0].programName =
                    ((_s = payload.BasicDetails.customerOrPartnerSelection) === null || _s === void 0 ? void 0 : _s.key) === "customer"
                        ? (_t = payload.EscalationDetails.program) === null || _t === void 0 ? void 0 : _t.text
                        : (_u = payload.EscalationDetails.program) === null || _u === void 0 ? void 0 : _u.text;
            }
        }
        if (((_v = payload.BasicDetails.customerLocation) === null || _v === void 0 ? void 0 : _v.text) !== "") {
            const country = fetchCountryDetails((_w = payload.BasicDetails.customerLocation) === null || _w === void 0 ? void 0 : _w.text);
            transformedEscalation.Country = {
                id: country === null || country === void 0 ? void 0 : country.id,
                name: country === null || country === void 0 ? void 0 : country.name,
                alpha2: country === null || country === void 0 ? void 0 : country.alpha2,
                alpha3: country === null || country === void 0 ? void 0 : country.alpha3,
            };
        }
        if (((_x = payload.BasicDetails.partnerLocation) === null || _x === void 0 ? void 0 : _x.text) !== "") {
            const country = fetchCountryDetails((_y = payload.BasicDetails.partnerLocation) === null || _y === void 0 ? void 0 : _y.text);
            transformedEscalation.Country = {
                id: country === null || country === void 0 ? void 0 : country.id,
                name: country === null || country === void 0 ? void 0 : country.name,
                alpha2: country === null || country === void 0 ? void 0 : country.alpha2,
                alpha3: country === null || country === void 0 ? void 0 : country.alpha3,
            };
        }
        if (flowType === FlowTypeSelector.License ||
            flowType === FlowTypeSelector.CPP ||
            flowType === FlowTypeSelector.TechProductIssue) {
            let segmentName = "";
            if (((_z = payload.BasicDetails.customerOrPartnerSelection) === null || _z === void 0 ? void 0 : _z.key) === "partner") {
                transformedEscalation.AccountInfo = Object.assign(Object.assign({}, transformedEscalation.AccountInfo), { CompanyName: (_0 = payload.BasicDetails.partner) === null || _0 === void 0 ? void 0 : _0.partnerName });
                segmentName = "Partner";
            }
            else {
                segmentName = "Commercial";
            }
            transformedEscalation.CustomerInfo = Object.assign(Object.assign({}, transformedEscalation.CustomerInfo), { CustomerSegmentName: segmentName });
        }
        if (payload.metaInfo) {
            transformedEscalation.MetaInfo = payload.metaInfo;
        }
        submitCommercialEscalation(transformedEscalation, contextDispatch);
    };
    const commercialFlowDispatch = {
        update_BasicDetails: update_BasicDetails,
        update_EscalationDetails: update_EscalationDetails,
        set_BasicDetailsAreValid: set_BasicDetailsAreValid,
        set_EscalationDetailsAreValid: set_EscalationDetailsAreValid,
        submit_CommercialEscalation: submit_CommercialCase,
    };
    return (_jsx(CommercialFlowContext.Provider, Object.assign({ value: context }, { children: _jsx(CommercialFlowContextDispatch.Provider, Object.assign({ value: commercialFlowDispatch }, { children: children })) })));
};
