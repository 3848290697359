import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef, useMemo } from "react";
import { Stack } from "@fluentui/react/lib/Stack";
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities";
import { Text, StackItem, Icon, DocumentCard, Link, } from "@fluentui/react";
import { defaultAttachmentStyle } from "./ElxAttachmentDetail.styles";
import { ElxIconButton } from "@elixir/components/lib/Button";
import { useDropzone } from "react-dropzone";
const buttonTooltipProps = {
    styles: { host: { float: "right " } },
};
const clearIcon = { iconName: "Clear" };
const extNamesObject = {
    PictureFill: ["png", "jpg", "jpeg", "gif"],
    WordDocument: ["doc", "docx"],
    ExcelDocument: ["xlsx", "xls"],
    PDF: ["pdf"],
};
const getAttachmentClasses = classNamesFunction();
const getFileTypeIconName = (fileName) => {
    var _a;
    const fileSuffix = String(fileName === null || fileName === void 0 ? void 0 : fileName.split(".").pop()).toLowerCase();
    const iconName = ((_a = Object.entries(extNamesObject).find(([key, value]) => value.includes(fileSuffix))) === null || _a === void 0 ? void 0 : _a[0]) || "TextDocument";
    return iconName;
};
const attachmentsListView = (uploadMode, attachmentsExisted, attachmentsAwaitingForUpload, classnames, onDelete, setFilesToBeUpload) => {
    const removeAttachmentFromUploading = (selectedAttachment) => {
        for (let i = 0; i < attachmentsAwaitingForUpload.length; i++) {
            if (selectedAttachment.fileName === attachmentsAwaitingForUpload[i].fileName) {
                setFilesToBeUpload === null || setFilesToBeUpload === void 0 ? void 0 : setFilesToBeUpload([
                    ...attachmentsAwaitingForUpload.slice(0, i),
                    ...attachmentsAwaitingForUpload.slice(i + 1),
                ]);
                return;
            }
        }
    };
    return (_jsxs(React.Fragment, { children: [!uploadMode &&
                (attachmentsExisted === null || attachmentsExisted === void 0 ? void 0 : attachmentsExisted.map((attachment) => (_jsxs(DocumentCard, Object.assign({ styles: classnames.subComponentStyles.attachmentDocumentCard }, { children: [_jsxs(Stack, Object.assign({ styles: classnames.subComponentStyles.attachmentDocumentCardTextStack }, { children: [_jsx(Icon, { styles: classnames.subComponentStyles.attachmentIcon, iconName: getFileTypeIconName(attachment.fileName) }), _jsx(Link, Object.assign({ styles: classnames.subComponentStyles.attachmentLink, target: "_blank", href: attachment.fileUrl, download: true }, { children: attachment.fileName }))] })), onDelete && (_jsx(ElxIconButton, { text: "Remove", iconProps: clearIcon, tooltipProps: buttonTooltipProps, onClick: () => onDelete(attachment.fileName) }))] }), attachment.fileName)))), uploadMode &&
                (attachmentsAwaitingForUpload === null || attachmentsAwaitingForUpload === void 0 ? void 0 : attachmentsAwaitingForUpload.map((attachment) => (_jsxs(DocumentCard, Object.assign({ styles: classnames.subComponentStyles.attachmentDocumentCard }, { children: [_jsxs(Stack, Object.assign({ horizontal: true, styles: classnames.subComponentStyles.attachmentDocumentCardTextStack }, { children: [_jsx(Icon, { styles: classnames.subComponentStyles.attachmentIcon, iconName: getFileTypeIconName(attachment.fileName) }), _jsx(Text, Object.assign({ styles: classnames.subComponentStyles.attachmentFont }, { children: attachment.fileName }))] })), attachmentsAwaitingForUpload &&
                            setFilesToBeUpload &&
                            removeAttachmentFromUploading && (_jsx(ElxIconButton, { tooltipProps: buttonTooltipProps, text: "Remove", iconProps: clearIcon, onClick: (ev) => {
                                removeAttachmentFromUploading(attachment);
                                ev.stopPropagation();
                            } }))] }), attachment.fileName))))] }));
};
const ElxAttachmentDetailBase = (props) => {
    var _a;
    const classnames = useMemo(() => getAttachmentClasses(props.styles), [props.styles]);
    const [errorText, setErrorText] = useState("");
    const onDrop = (droppedFiles) => {
        if (droppedFiles.length > 0) {
            uploadFiles(droppedFiles);
        }
    };
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
    });
    const hiddenFileInputButton = useRef(null);
    const inputFileButtonClick = (event) => {
        const element = event.target;
        element.value = "";
        if (hiddenFileInputButton.current) {
            hiddenFileInputButton.current.click();
        }
    };
    const changeHandler = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFiles = Object.values(event.target.files);
            uploadFiles(selectedFiles);
        }
    };
    const validateFile = (file) => {
        setErrorText("");
        if (props.allowedFileTypes &&
            props.allowedFileTypes.indexOf(file.name.split(".").slice(-1)[0].toLowerCase()) <= -1) {
            // Wrong file type
            throw new Error("File type not supported");
        }
        if (file.size / Math.pow(1024, 2) > props.maxSizeForAttachment) {
            // Size greater than max
            throw new Error(`File size not allowed to exceed ${props.maxSizeForAttachment}MB`);
        }
        let totalFileSize = file.size;
        props.filesToBeUpload.forEach((item) => {
            var _a;
            totalFileSize += (_a = item.size) !== null && _a !== void 0 ? _a : 0;
        });
        if (totalFileSize / Math.pow(1024, 2) > props.totalMaxSizeForAttachments) {
            // Size greater than total max
            throw new Error(`Total files size not allowed to exceed ${props.totalMaxSizeForAttachments}MB`);
        }
    };
    const uploadFiles = (selectedFiles) => {
        selectedFiles = selectedFiles.filter((dropObj) => {
            return !props.filesToBeUpload.some((obj) => {
                return obj.fileName == dropObj["name"];
            });
        });
        if (selectedFiles.length > 0) {
            readAllFiles(selectedFiles)
                .then((files) => {
                const newFiles = files;
                props.setFilesToBeUpload([...props.filesToBeUpload, ...newFiles]);
            })
                .catch((error) => {
                setErrorText(error.message);
            });
        }
    };
    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            validateFile(file);
            const reader = new FileReader();
            reader.onload = (onloadEvent) => {
                var _a;
                const uploadedFile = {
                    fileName: file.name,
                    size: file.size,
                    contentBase64: (_a = onloadEvent.target.result) === null || _a === void 0 ? void 0 : _a.split(",")[1],
                    contentType: file.type !== "" ? file.type : "application/octet-stream",
                };
                resolve(uploadedFile);
            };
            reader.onerror = (event) => {
                reject(event.target.error);
            };
            reader.readAsDataURL(file);
        });
    };
    const readAllFiles = (files) => {
        const filePromises = Array.from(files).map((file) => readFileContent(file));
        return Promise.all(filePromises);
    };
    return (_jsx(Stack, Object.assign({ horizontalAlign: "center", "aria-label": "Attachments: Drag and drop files here or hit enter to browse for files" }, getRootProps(), { children: _jsxs(Stack, Object.assign({ styles: classnames.subComponentStyles.attachmentAddContainer, tabIndex: 0, tokens: {
                childrenGap: 10,
                padding: '2rem',
            } }, { children: [props.filesToBeUpload.length === 0 && (_jsx(Icon, { iconName: "CloudUpload", style: { fontSize: '50px', color: '#0078D4' } })), props.filesToBeUpload.length === 0 ? (_jsxs(StackItem, Object.assign({ styles: classnames.subComponentStyles.attachmentLabel }, { children: [_jsx(Text, { children: "Drag and drop files here" }), _jsx("br", {}), _jsx(Text, { children: "or" }), _jsx("br", {}), _jsx(Link, Object.assign({ styles: classnames.subComponentStyles.browseFileLink, onClick: inputFileButtonClick }, { children: "Browse for files" }))] }))) : (_jsx(StackItem, Object.assign({ styles: classnames.subComponentStyles.attachmentListContainer }, { children: attachmentsListView(props.filesToBeUpload.length > 0, (_a = props.attachments) !== null && _a !== void 0 ? _a : [], props.filesToBeUpload, classnames, props.onDelete, props.setFilesToBeUpload) }))), props.filesToBeUpload.length > 0 && (_jsxs(StackItem, Object.assign({ styles: classnames.subComponentStyles.attachmentLabel2 }, { children: [_jsx(Text, { children: "Drag and drop files here or " }), _jsx(Link, Object.assign({ onClick: inputFileButtonClick }, { children: "Browse for files" }))] }))), _jsx("input", Object.assign({ type: "file", hidden: true, ref: hiddenFileInputButton }, getInputProps(), { onChange: changeHandler, multiple: true })), errorText !== '' && (_jsx(Text, Object.assign({ styles: classnames.subComponentStyles.attachmentErrorText }, { children: errorText })))] })) })));
};
export const AttachmentDetail = styled(ElxAttachmentDetailBase, defaultAttachmentStyle, undefined, {
    scope: "AttachmentDetail",
});
