var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Suspense, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ElxMasterDetailContainer, ElxMessageBarType, ElxTabControlOrientation, } from "@elixir/components";
import EscalationSubmittedDetailPage from "../components/EscalationSubmittedPage/EscalationSubmittedDetailPage";
import { AcknowledgementDetail } from "../components/LicenseQ/Acknowledgement/AcknowledgementDetail";
import { CustomerDataContextProvider } from "../reducers/CustomerSearchContext/CustomerSearchContextProvider";
import CustomHeader from "../commoncomponents/CustomHeader";
import { FlowTypeSelector } from "../models";
import { useCommercialFlowContext, useCommercialFlowContextDispatch, useCommercialContextDispatch, useCommercialContextState, } from "../reducers";
import GetTabComponents, { TabType } from "./Helper/GetTabComponents";
import { GetSubHeader } from "./Helper/GetSubHeader";
import { useLocaleStrings } from "../library/providers/strings";
import { useRootContext } from "../library/providers/root/RootContextProvider";
import { validateCaseBoundaries } from "../services";
import { getDataFromSessionStorage } from "../config/configHelper";
import { TENANTID_DESC_HEAD, STAR_SEPARATOR } from "../constants/strings";
export const CommercialContainer = (props) => {
    const { isConfigurationSet, globalData: { source } } = useRootContext();
    const { getLocalSpecificString } = useLocaleStrings();
    const commercialContextState = useCommercialContextState();
    const setCommercialContextState = useCommercialContextDispatch();
    const commercialFlowContextState = useCommercialFlowContext();
    const [EUFlag, setEUFlag] = useState(false);
    const { submit_CommercialEscalation } = useCommercialFlowContextDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [basicsNextClicked, setBasicsNextClicked] = useState(0);
    const [escalationNextClicked, setEscalationNextClicked] = useState(0);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [validateCaseboundariesInProgress, setvalidateCaseboundariesInProgress,] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(undefined);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    // Used to route the user back to the Escalations Tab when Cancelled
    const history = useHistory();
    const onCancel = () => {
        history.push("/");
    };
    // Function to validate commercial escalation
    // Validates support request, Tenant Info (Pending), Country.
    const ValidateEU = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const supportCasesList = (_a = commercialFlowContextState.EscalationDetails.supportTicketIds) === null || _a === void 0 ? void 0 : _a.map((item) => item.text);
        const tenantIdsList = (_b = commercialFlowContextState.EscalationDetails.tenantIds) === null || _b === void 0 ? void 0 : _b.map((item) => item.text);
        // Handling Partner Location
        const countryText = ((_d = (_c = payload.BasicDetails) === null || _c === void 0 ? void 0 : _c.customerLocation) === null || _d === void 0 ? void 0 : _d.text) != ""
            ? (_f = (_e = payload.BasicDetails) === null || _e === void 0 ? void 0 : _e.customerLocation) === null || _f === void 0 ? void 0 : _f.text
            : (_h = (_g = payload.BasicDetails) === null || _g === void 0 ? void 0 : _g.partnerLocation) === null || _h === void 0 ? void 0 : _h.text;
        const response = yield validateCaseBoundaries({
            country: countryText,
            supportCases: supportCasesList,
            tenantId: tenantIdsList,
            subscriptionId: payload.EscalationDetails.azureSubscriptionID,
            tenantName: (_j = payload.EscalationDetails) === null || _j === void 0 ? void 0 : _j.tenantName,
            isAzure: payload.isAzure,
        });
        return response;
    });
    const onSubmit = () => {
        var _a, _b;
        // Setting isAzure Flag true for Azure Escalations.
        // Adding this at submit click for EUDB Validation.
        if (commercialFlowContextState.EscalationDetails.parentProduct.text ===
            "Azure") {
            commercialFlowContextState.isAzure = true;
        }
        // Setting isAzure Flag true for Azure Escalations.
        // Adding this at submit click for EUDB Validation.
        if (commercialFlowContextState.EscalationDetails.parentProduct.text ===
            "Azure") {
            commercialFlowContextState.isAzure = true;
        }
        /*
          In the below code we are appending tenantIds to Issue Description, if any tenantIds are present, Generating the HTML like below
          <div>
          <div>
            <div>**************************************</div>
            <div>Tenant ID(s) provided by the Submitter: <br> Tid1, Tid2 </div>
          </div>
          <div>**************************************</div>
          <div>
            <div>Sample Description</div>
          </div>
        </div>
        */
        let issueDescTIds = commercialFlowContextState.EscalationDetails.issueDescription;
        //TODO:: Introduce sanitization library and sanitize issueDescription 
        const { issueDescription, tenantIds } = commercialFlowContextState.EscalationDetails;
        if (tenantIds && tenantIds.length !== 0) {
            const tenantIdsText = tenantIds.map(t => t.text).join(", ");
            const pDiv = document.createElement('div');
            const tIdsDiv = document.createElement('div');
            const starSeperatorOpen = document.createElement('div');
            starSeperatorOpen.textContent = STAR_SEPARATOR;
            tIdsDiv.appendChild(starSeperatorOpen);
            const tenantHeadingdiv = document.createElement('div');
            tenantHeadingdiv.textContent = TENANTID_DESC_HEAD;
            tenantHeadingdiv.appendChild(document.createElement('br'));
            tenantHeadingdiv.appendChild(document.createTextNode(` ${tenantIdsText}`));
            tIdsDiv.appendChild(tenantHeadingdiv);
            pDiv.appendChild(tIdsDiv);
            const starSeperatorClose = document.createElement('div');
            starSeperatorClose.textContent = STAR_SEPARATOR;
            pDiv.appendChild(starSeperatorClose);
            const issueDescDiv = document.createElement('div');
            issueDescDiv.innerHTML = issueDescription;
            pDiv.appendChild(issueDescDiv);
            issueDescTIds = pDiv.outerHTML;
        }
        let metaInfo;
        if (source) {
            metaInfo = {
                source
            };
        }
        // Validate EU Case Boundaries
        if (((_a = getDataFromSessionStorage("isEUEnabled")) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true" &&
            !(flowinfo.FlowType === FlowTypeSelector.ELP && ((_b = commercialFlowContextState.BasicDetails.isCustomerOrCommercialCase) === null || _b === void 0 ? void 0 : _b.key) === "consumer")) {
            setvalidateCaseboundariesInProgress(true);
            ValidateEU(commercialFlowContextState).then((result) => {
                var _a, _b, _c;
                const commercialFlowState = Object.assign(Object.assign({}, commercialFlowContextState), { isEU: (_a = result === null || result === void 0 ? void 0 : result.result) === null || _a === void 0 ? void 0 : _a.isEU, EscalationDetails: Object.assign(Object.assign({}, commercialFlowContextState.EscalationDetails), { issueDescription: issueDescTIds }) });
                if (metaInfo) {
                    commercialFlowState.metaInfo = metaInfo;
                }
                setEUFlag((_c = (_b = result === null || result === void 0 ? void 0 : result.result) === null || _b === void 0 ? void 0 : _b.isEU) !== null && _c !== void 0 ? _c : false);
                setSubmitInProgress(true);
                submit_CommercialEscalation(commercialFlowState, flowinfo.FlowType, GetSubHeader(flowinfo.FlowType));
            });
        }
        else {
            setSubmitInProgress(true);
            const commercialFlowState = Object.assign(Object.assign({}, commercialFlowContextState), { EscalationDetails: Object.assign(Object.assign({}, commercialFlowContextState.EscalationDetails), { issueDescription: issueDescTIds }) });
            if (metaInfo) {
                commercialFlowState.metaInfo = metaInfo;
            }
            submit_CommercialEscalation(commercialFlowState, flowinfo.FlowType, GetSubHeader(flowinfo.FlowType));
        }
    };
    useEffect(() => {
        setSubmitInProgress(commercialFlowContextState.InProgress);
        if (commercialFlowContextState.Error.length > 0) {
            const displayError = {
                message: commercialFlowContextState.Error,
                type: ElxMessageBarType.error,
                iconProps: { iconName: "Error" },
            };
            setDisplayMessage(displayError);
        }
        else {
            if (submitInProgress) {
                const displayError = {
                    message: "Escalation submission is in progress.",
                    type: ElxMessageBarType.error,
                    iconProps: { iconName: "InfoSolid" },
                };
                setDisplayMessage(displayError);
            }
            else if (validateCaseboundariesInProgress) {
                const displayError = {
                    message: "EUDB Case Boundaries validation is in progress.",
                    type: ElxMessageBarType.error,
                    iconProps: { iconName: "InfoSolid" },
                };
                setDisplayMessage(displayError);
            }
            else
                setDisplayMessage(undefined);
        }
    }, [
        commercialFlowContextState.InProgress,
        commercialFlowContextState.Error,
        validateCaseboundariesInProgress,
    ]);
    function BasicComponent(flowtype) {
        return (_jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(GetTabComponents, { flowType: `${flowtype}-${TabType.Basics}`, basicsNextClicked: basicsNextClicked, escalationNextClicked: escalationNextClicked, moveToNextTab: moveToNextTab }) })));
    }
    function EscalationDetailsComponent(flowtype) {
        return (_jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(GetTabComponents, { flowType: `${flowtype}-${TabType.EscalationDetails}`, basicsNextClicked: basicsNextClicked, escalationNextClicked: escalationNextClicked, moveToNextTab: moveToNextTab }) })));
    }
    function ReviewComponent(flowtype) {
        return (_jsx(Suspense, Object.assign({ fallback: _jsx("div", { children: "Loading..." }) }, { children: _jsx(GetTabComponents, { flowType: `${flowtype}-${TabType.Review}`, basicsNextClicked: basicsNextClicked, escalationNextClicked: escalationNextClicked }) })));
    }
    // Loading metadata - Countries, Products, Programs
    useEffect(() => {
        if (isConfigurationSet)
            setCommercialContextState.getCommercialMetadata();
    }, [isConfigurationSet]);
    useEffect(() => {
        setIsLoading(commercialContextState.InProgress);
    }, [commercialContextState.InProgress]);
    const flowinfo = props;
    const moveToNextTab = (isValid) => {
        if (isValid) {
            setCurrentTabIndex((prev) => prev + 1);
        }
        setIsLoading(false);
    };
    const tabs = [
        {
            key: "1",
            name: "Basics",
            component: BasicComponent(flowinfo.FlowType),
            primaryButton: {
                disabled: false,
                onClick: (_event) => {
                    setIsLoading(true);
                    setBasicsNextClicked((prevState) => prevState + 1);
                },
            },
            cancelButton: {
                text: "Close",
                onClick: onCancel,
            },
            isCompleted: commercialFlowContextState.areBasicDetailsValid,
        },
        {
            key: "2",
            name: "Escalation Details",
            component: EscalationDetailsComponent(flowinfo.FlowType),
            primaryButton: {
                disabled: false,
                onClick: (_event) => {
                    setIsLoading(true);
                    setEscalationNextClicked((prevState) => prevState + 1);
                },
            },
            cancelButton: {
                text: "Close",
                onClick: onCancel,
            },
            backButton: {
                text: "Previous",
            },
            disabled: !commercialFlowContextState.areBasicDetailsValid,
            isCompleted: commercialFlowContextState.areEscalationDetailsValid,
        },
        {
            key: "3",
            name: "Review",
            component: ReviewComponent(flowinfo.FlowType),
            primaryButton: {
                text: "Submit",
                disabled: !commercialFlowContextState.areBasicDetailsValid ||
                    !commercialFlowContextState.areEscalationDetailsValid ||
                    submitInProgress ||
                    validateCaseboundariesInProgress,
                onClick: onSubmit,
            },
            cancelButton: {
                text: "Close",
                onClick: onCancel,
            },
            backButton: {
                text: "Previous",
            },
            disabled: !commercialFlowContextState.areEscalationDetailsValid ||
                !commercialFlowContextState.areBasicDetailsValid,
        },
    ];
    //move it all headings and subsheadings to strings library. no hardcoding please.
    return (_jsx(_Fragment, { children: props.FlowType === FlowTypeSelector.License &&
            !commercialContextState.CommercialContext.EnableAcknowledgement ? (_jsx(AcknowledgementDetail, {})) : (_jsx(_Fragment, { children: !commercialFlowContextState.uemTrackingId ? (_jsx("div", { children: _jsx(CustomerDataContextProvider, { children: _jsx(ElxMasterDetailContainer, { onRenderHeader: () => (_jsx(CustomHeader, { onCancel: onCancel, headerText: getLocalSpecificString("pageHeader"), iconButtonText: "" })), subHeaderText: GetSubHeader(props.FlowType), wizard: true, tabsOrientation: ElxTabControlOrientation.Horizontal, selectedTabIndex: currentTabIndex, primaryIsCustom: true, showNumeric: true, maxDisplayActions: 3, tabs: tabs, onTabChange: (tabIndex) => {
                            setCurrentTabIndex(tabIndex);
                        }, isLoading: isLoading, message: displayMessage, hasPrevButton: true, styles: {
                            root: { minHeight: "50vh" },
                            dropdownSection: { paddingTop: "15px" },
                        } }, currentTabIndex) }) })) : (_jsx(EscalationSubmittedDetailPage, { uemTrackingId: commercialFlowContextState.uemTrackingId, subHeaderTitle: GetSubHeader(props.FlowType), escalationType: 1, isEU: EUFlag, submitInProgress: submitInProgress, submitFailedErrorMsg: commercialFlowContextState.Error })) })) }));
};
