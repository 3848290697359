import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useEffect, useState } from "react";
import { ElxChoiceGroup, ElxCombobox, ElxTextField, InputMessageTypes, } from "@elixir/components";
import { Label, Link, Stack, } from "@fluentui/react";
import { FieldLabel } from "../../commoncomponents/FieldLabel";
import { ElxMultiSelectPeoplePicker } from "../../commoncomponents/MultiSelectPeoplePicker/ElxPeopleMultiPicker";
import { useRootContext } from "../../library/providers/root/RootContextProvider";
import { UseConsumerContextDispatch, UseConsumerContextState, } from "../../reducers";
import { useLocaleStrings } from "../../library/providers/strings";
import { isValidEmail, isValidName, isValidNumber, } from "../../library/utilities/FieldValidation";
import "../../styles/EscalationForm.css";
import "./HomeUser.css";
import TooltipWrapper from "../../commoncomponents/TooltipWrapper";
export const BasicsTab = (props) => {
    const { getLocalSpecificString } = useLocaleStrings();
    const microsoftAccIdRef = React.useRef(null);
    const [isTabInitialized, setIsTabInitialized] = useState(true);
    const { privileges: { isQALoB }, } = useRootContext();
    const consumerContextState = UseConsumerContextState();
    const setConsumerContextState = UseConsumerContextDispatch();
    const [basicDetails, setBasicDetails] = useState(consumerContextState.ConsumerContext.BasicDetails);
    const [validationStatus, setValidationStatus] = useState(consumerContextState.ConsumerContext.areBasicDetailsValid !== true
        ? {
            validCustomerFirstName: undefined,
            validCustomerLastName: undefined,
            validCustomerLocation: undefined,
            validCustomerEmail: undefined,
            validCustomerPhone: true,
            validMicrosoftAccountId: true,
        }
        : {
            validCustomerFirstName: true,
            validCustomerLastName: true,
            validCustomerLocation: true,
            validCustomerEmail: true,
            validCustomerPhone: true,
            validMicrosoftAccountId: true,
        });
    const choiceGroupOptions = {
        Yes: { key: "Yes", text: "Yes" },
        No: { key: "No", text: "No" },
    };
    useEffect(() => {
        if (props.basicsNextClicked > 0 && !isTabInitialized) {
            validateBasicsTab();
        }
    }, [props.basicsNextClicked]);
    useEffect(() => {
        if (isTabInitialized) {
            setIsTabInitialized(false);
        }
        else {
            let isValid = undefined;
            if (Object.values(validationStatus).every((value) => value === true)) {
                isValid = true;
            }
            else if (Object.values(validationStatus).some((value) => value === false)) {
                isValid = false;
            }
            setConsumerContextState.UpdateBasicDetails(basicDetails);
            setConsumerContextState.UpdateBasicDetailsValidationStatus(isValid);
        }
    }, [
        validationStatus,
        basicDetails.submitterEmail,
        basicDetails.preferredLanguage,
    ]);
    const validateBasicsTab = () => {
        const validationResult = {
            validCustomerFirstName: basicDetails.customerFirstName.trim() != "" &&
                isValidName(basicDetails.customerFirstName),
            validCustomerLastName: basicDetails.customerLastName.trim() != "" &&
                isValidName(basicDetails.customerLastName),
            validCustomerLocation: basicDetails.customerLocation != "",
            validCustomerEmail: !basicDetails.haveCustomerEmail ||
                isValidEmail(basicDetails.customerEmail),
            validCustomerPhone: isValidPhoneNumber(basicDetails.customerPhone),
            validMicrosoftAccountId: basicDetails.microsoftAccountId == "" ||
                isValidEmail(basicDetails.microsoftAccountId),
        };
        setValidationStatus(validationResult);
        announceErrors();
        let isValid = true;
        Object.values(validationResult).forEach((x) => {
            if (x === false || x === undefined) {
                isValid = false;
                return false;
            }
        });
        // delay as workaround to make sure context is updated before this
        setTimeout(() => {
            if (props.moveToNextTab)
                props.moveToNextTab(isValid);
        }, 500);
    };
    const announceErrors = () => {
        const errorElement = document.querySelector(".has-error");
        if (errorElement) {
            const liveElements = errorElement.querySelectorAll('div[aria-live="assertive"]');
            const firstErrorElement = Array.from(liveElements).find((element) => element.innerText.trim() !== '');
            //trigger change in aria-live content to announce error
            if (firstErrorElement) {
                const htmlContent = firstErrorElement.innerHTML;
                firstErrorElement.innerHTML = htmlContent;
            }
        }
    };
    const isValidPhoneNumber = (input, haveCustomerEmail = basicDetails.haveCustomerEmail) => {
        if (haveCustomerEmail) {
            return !input || input == "" || isValidNumber(input, true);
        }
        else {
            return input != undefined && isValidNumber(input, true);
        }
    };
    const setSubmitterEmail = (user) => {
        setBasicDetails((prevState) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, prevState), { submitterName: (_a = user === null || user === void 0 ? void 0 : user.displayName) !== null && _a !== void 0 ? _a : "", submitterEmail: (_b = user === null || user === void 0 ? void 0 : user.account) !== null && _b !== void 0 ? _b : "" }));
        });
    };
    const setCustomerFirstName = (_event, newValue) => {
        setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { customerFirstName: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const setCustomerLastName = (_event, newValue) => {
        setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { customerLastName: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const setCustomerEmail = (_event, newValue) => {
        setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { customerEmail: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const setCustomerPhone = (_event, newValue) => {
        setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { customerPhone: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const setCustomerLocation = (_event, option, _index) => {
        if (option) {
            setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { customerLocation: option.key.toString() })));
            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validCustomerLocation: true })));
        }
    };
    const setHaveCustomerEmail = (_event, option) => {
        setBasicDetails((prevState) => {
            if ((option === null || option === void 0 ? void 0 : option.key) == "Yes") {
                return Object.assign(Object.assign({}, prevState), { haveCustomerEmail: true });
            }
            else {
                return Object.assign(Object.assign({}, prevState), { haveCustomerEmail: false, customerEmail: "" });
            }
        });
        setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validCustomerEmail: !((option === null || option === void 0 ? void 0 : option.key) == "Yes") || isValidEmail(basicDetails.customerEmail)
                ? true
                : undefined, validCustomerPhone: isValidPhoneNumber(basicDetails.customerPhone, (option === null || option === void 0 ? void 0 : option.key) == "Yes")
                ? true
                : undefined })));
    };
    const setMicrosoftAccountId = (_event, newValue) => {
        setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { microsoftAccountId: newValue !== null && newValue !== void 0 ? newValue : "" })));
    };
    const setPreferredLanguage = (_event, option, _index) => {
        if (option) {
            setBasicDetails((prevState) => (Object.assign(Object.assign({}, prevState), { preferredLanguage: option.key.toString() })));
        }
    };
    return (_jsxs(Stack, Object.assign({ className: "escalationForm", tokens: { childrenGap: 10 } }, { children: [isQALoB && (_jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ id: "submitterPicker-label" }, { children: getLocalSpecificString("submitterLabel") })), _jsx(ElxMultiSelectPeoplePicker, { id: "submitterPicker", multiSelect: false, placeholder: getLocalSpecificString("submitterPH"), value: basicDetails.submitterEmail != ""
                            ? {
                                account: basicDetails.submitterEmail,
                                displayName: basicDetails.submitterName,
                                name: "",
                                alias: "",
                                image: "",
                            }
                            : undefined, onChange: setSubmitterEmail, searchAcrossAllDomain: true })] }))), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: true, id: "customerFirstNameLabel" }, { children: getLocalSpecificString("customerFirstNameLabel") })), _jsx(ElxTextField, { "aria-labelledby": "customerFirstNameLabel", placeholder: getLocalSpecificString("customerFirstNamePH"), maxLength: 25, value: basicDetails.customerFirstName, onChange: setCustomerFirstName, required: true, invalid: validationStatus.validCustomerFirstName === false, onBlur: () => {
                            const isValid = basicDetails.customerFirstName.trim() != "" &&
                                isValidName(basicDetails.customerFirstName);
                            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validCustomerFirstName: isValid })));
                        }, message: validationStatus.validCustomerFirstName === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please enter a valid first name",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: true, id: "customerLastNameLabel" }, { children: getLocalSpecificString("customerLastNameLabel") })), _jsx(ElxTextField, { "aria-labelledby": "customerLastNameLabel", placeholder: getLocalSpecificString("customerLastNamePH"), maxLength: 25, value: basicDetails.customerLastName, onChange: setCustomerLastName, required: true, invalid: validationStatus.validCustomerLastName === false, onBlur: () => {
                            const isValid = basicDetails.customerLastName.trim() != "" &&
                                isValidName(basicDetails.customerLastName);
                            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validCustomerLastName: isValid })));
                        }, message: validationStatus.validCustomerLastName === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please enter a valid last name",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: true, id: "customerLocationLabel" }, { children: getLocalSpecificString("customerLocationLabel") })), _jsx(ElxCombobox, { "aria-labelledby": "customerLocationLabel", placeholder: getLocalSpecificString("customerLocationPH"), options: consumerContextState.ConsumerContext.ConsumerMetadata.countries, selectedKey: basicDetails.customerLocation, onChange: setCustomerLocation, allowFreeInput: true, required: true, message: validationStatus.validCustomerLocation === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please select a valid location",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: true, id: "haveCustomerEmailLabel" }, { children: getLocalSpecificString("haveCustomerEmailLabel") })), _jsx(ElxChoiceGroup, { "aria-labelledby": "haveCustomerEmailLabel", required: true, options: [choiceGroupOptions.Yes, choiceGroupOptions.No], selectedKey: basicDetails.haveCustomerEmail == true
                            ? choiceGroupOptions.Yes.key
                            : choiceGroupOptions.No.key, onChange: setHaveCustomerEmail })] })), basicDetails.haveCustomerEmail && (_jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ required: basicDetails.haveCustomerEmail, id: "customerEmailLabel" }, { children: getLocalSpecificString("customerEmailLabel") })), _jsx(ElxTextField, { "aria-labelledby": "customerEmailLabel", placeholder: getLocalSpecificString("customerEmailPH"), value: basicDetails.customerEmail, onChange: setCustomerEmail, required: true, invalid: validationStatus.validCustomerEmail === false, onBlur: () => {
                            const isValid = !basicDetails.haveCustomerEmail ||
                                isValidEmail(basicDetails.customerEmail);
                            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validCustomerEmail: isValid })));
                        }, message: validationStatus.validCustomerEmail === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please enter a valid email address",
                            }
                            : undefined })] }))), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(FieldLabel, { id: "customerPhoneLabel", label: getLocalSpecificString("customerPhoneLabel"), mandatory: !basicDetails.haveCustomerEmail, toolTipContent: _jsxs(Label, { children: [_jsx("b", { children: "Customer Phone Number" }), _jsx("br", {}), "Enter Single Phone Number without spaces.", _jsx("br", {}), "Use Description for others."] }) }), _jsx(ElxTextField, { "aria-labelledby": "customerPhoneLabel", placeholder: "Enter Phone Number", maxLength: 20, value: basicDetails.customerPhone, onChange: setCustomerPhone, invalid: validationStatus.validCustomerPhone === false, onBlur: () => {
                            const isValid = isValidPhoneNumber(basicDetails.customerPhone);
                            setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validCustomerPhone: isValid })));
                        }, message: validationStatus.validCustomerPhone === false
                            ? {
                                type: InputMessageTypes.Error,
                                content: "Please enter a valid phone number",
                            }
                            : undefined })] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(FieldLabel, { id: "microsoftAccountIdLabel", label: getLocalSpecificString("microsoftAccountIdLabel"), mandatory: false, tooltip: _jsx(TooltipWrapper, Object.assign({ onTooltipBlur: () => {
                                var _a;
                                const input = (_a = microsoftAccIdRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('input');
                                input === null || input === void 0 ? void 0 : input.focus();
                            } }, { children: _jsxs(Label, { children: [_jsx("b", { children: "Customer Microsoft Account" }), _jsx("br", {}), "\u2022 Enter customer Skype or Email", _jsx("br", {}), "\u2022 Can be different from Customer Email Address", _jsx("br", {}), _jsx(Link, Object.assign({ href: "https://support.microsoft.com/en-us/account-billing/what-is-a-microsoft-account-4a7c48e9-ff5a-e9c6-5a5c-1a57d66c3bfa", target: "_blank" }, { children: "What is a Microsoft account? - Microsoft Support" }))] }) })) }), _jsx("div", Object.assign({ ref: microsoftAccIdRef }, { children: _jsx(ElxTextField, { "aria-labelledby": "microsoftAccountIdLabel", placeholder: getLocalSpecificString("microsoftAccountIdPH"), value: basicDetails.microsoftAccountId, onChange: setMicrosoftAccountId, invalid: validationStatus.validMicrosoftAccountId === false, onBlur: () => {
                                const isValid = !basicDetails.microsoftAccountId ||
                                    basicDetails.microsoftAccountId == "" ||
                                    isValidEmail(basicDetails.microsoftAccountId);
                                setValidationStatus((prevState) => (Object.assign(Object.assign({}, prevState), { validMicrosoftAccountId: isValid })));
                            }, message: validationStatus.validMicrosoftAccountId === false
                                ? {
                                    type: InputMessageTypes.Error,
                                    content: "Please enter a valid Microsoft Account Id",
                                }
                                : undefined }) }))] })), _jsxs(Stack.Item, Object.assign({ className: "form-row" }, { children: [_jsx(Label, Object.assign({ id: "customerPreferredLanguageLabel" }, { children: getLocalSpecificString("customerPreferredLanguageLabel") })), _jsx(ElxCombobox, { "aria-labelledby": "customerPreferredLanguageLabel", message: {
                            type: InputMessageTypes.Info,
                            content: "Select English for the fastest response.",
                        }, options: consumerContextState.ConsumerContext.ConsumerMetadata.languages, selectedKey: basicDetails.preferredLanguage, onChange: setPreferredLanguage, allowFreeInput: true })] }))] })));
};
