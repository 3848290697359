import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { createContext } from "react";
import { AppRole } from "../../../config/roles";
import { getConfig, pushAppConfigToSessionStorage, } from "../../../config/configHelper";
const initialConfig = {
    privileges: {
        isQALoB: false,
        isCLoB: false,
        isExecutive: false,
    },
    name: "",
    appConfiguration: {
        globalFrontdoorbaseurl: "",
        euFrontdoorbaseurl: "",
        myescalationsRoute: "",
        lookupTenantinfoRoute: "",
        lookupEngagementRoute: "",
        lookupPartnerinfoRoute: "",
        lookupSalesinfoRoute: "",
        lookupOpportunityRoute: "",
        getsupportrequestRoute: "",
        consumerDomaindataRoute: "",
        createConsumerEscalationRoute: "",
        createCommercialEscalationRoute: "",
        commercialDomaindataRoute: "",
        consumerFollowupRoute: "",
        commercialFollowupRoute: "",
        redirectURI: "",
        scopes: "",
        appClientID: "",
        appTenantID: "",
        authType: "",
        applicationTitle: "",
        applicationIconName: "",
        rm2SummaryPageLink: "",
        rm2SearchPageLink: "",
        rm2EuSummaryPageLink: "",
        rm2EuSearchPageLink: "",
        gethelpPortalLink: "",
        euGethelpPortalLink: "",
        isEUEnabled: false,
    },
    globalData: {
        source: ""
    },
    isConfigurationSet: false,
};
export const RootContext = createContext(initialConfig);
export const useRootContext = () => {
    return React.useContext(RootContext);
};
export const RootContextProvider = (props) => {
    const [config, setConfig] = React.useState(initialConfig);
    React.useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const params = new URLSearchParams(parsedUrl.search);
        const source = params.get("source") || "";
        getConfig()
            .then((appConfig) => {
            const rolesList = appConfig.roles;
            const name = appConfig.name;
            const config = appConfig.config;
            const updatedConfig = {
                privileges: {
                    isQALoB: rolesList.includes(AppRole.QALoB),
                    isCLoB: rolesList.includes(AppRole.CLoB),
                    isExecutive: rolesList.includes(AppRole.Executive),
                },
                name: name,
                appConfiguration: config,
                globalData: { source },
                isConfigurationSet: true,
            };
            setConfig(updatedConfig);
            pushAppConfigToSessionStorage(config);
        })
            .catch((error) => {
            console.log("Unable to fetch user's access privileges. ", error);
        });
    }, []);
    return (_jsx(RootContext.Provider, Object.assign({ value: config }, { children: props.children })));
};
